import { template as template_88eac315794d462ba00c40ee227cd583 } from "@ember/template-compiler";
const FKControlMenuContainer = template_88eac315794d462ba00c40ee227cd583(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
