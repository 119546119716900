import { template as template_c6eb7536f0e142bcaf514ce502586c85 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c6eb7536f0e142bcaf514ce502586c85(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
